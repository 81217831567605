.h_carousel{
    height: auto;
    background-color: #295e57c4;
    margin-top: 10rem;
    color: white;
   padding-bottom: 7vh; 
}
.home_gallery_card{
    margin-top: 2rem;
    display: flex;
}
.h_carousel h1{
    font-size: 30pt;
    font-weight: 800;
    padding: 2rem;
    font-family: Kaushan Script;
    text-shadow: 
    1px 1px 0 #e5c100,
    2px 2px 0 #cba900,
    3px 3px 0 #b0a200,
    4px 4px 0 #968b00,
    5px 5px 0 #7d7400,
    6px 6px 0 #645d00,
    7px 7px 0 #4a4600;
}
.home_photo_gallery_container{
    height: 35rem;
    width: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.home_gallery_photo{
    height: 31rem;
    width: 85vw;
    object-fit: cover;
    align-self: center;
    -webkit-box-shadow:0px 0px 30px 0px rgba(204,183,130,0.91);
    -moz-box-shadow: 0px 0px 30px 0px rgba(204,183,130,0.91);
    box-shadow: 0px 0px 30px 0px rgba(204,183,130,0.91);
}
.slick-list img{
    border-radius: 12px;
    margin-bottom: 2rem;
}
.slick-list img:hover{
position: relative;
top: -10px;
  } 
  .slick-prev,
  .slick-next {
    height: 200px;
    width: 100px;
    padding-top: 200px;
    z-index: 3;
    right: 0;
    top: 0;
  }
  .photo_name{
    align-self: center;
    padding: 1rem;
  }

  #flower-dot{
    font-size: 12pt;
  }
  .slick-dots {
    bottom: -60px;
  }
  .slick-dots ul{
    padding: 0rem;
  }

  .slick-active{
    color: #DBBA4B;
  }



  @media (min-width:851px) { 
    .home_gallery_photo{
      width: 45vw;
      object-fit: cover;
  }
  .h_carousel h1{
    font-size: 50pt;
  }
  #flower-dot{
    font-size: 14pt;
  }
  }
  @media (min-width:1024px) { 
    #flower-dot{
      font-size: 20pt;
    }
    .slick-dots li {
      padding: 0rem 0.5rem;
    }
    .slick-dots {

      bottom: -50px;
    }
  }


  @media (min-width:1481px) { 
    .h_carousel{
        align-self: center;
        width: 100vw;
    }
    .slick-prev,
    .slick-next {
      height: 200px;
      width: 100px;
      padding-top: 200px;
      z-index: 3;
    }
    .home_gallery_photo{
        height: 31rem;
        width: 40rem;
        object-fit: cover;
    }
    .h_carousel h1{
        font-size: 50pt;
        font-weight: 800;
        padding: 2rem;
    }
  }


  
  @media (min-width:1650px) {
    .home_gallery_photo{
      height: 35rem;
      width: 50rem;
      object-fit: cover;
  }
  .home_photo_gallery_container{
    height: auto;
}
  }

  @media (min-width:2030px) {
        .home_gallery_photo{
      height: 36rem;
      width: 40rem;
      object-fit: cover;
  }
  .home_photo_gallery_container{
    height: 38rem;
}
  }