.employment{
    height: auto;
    padding-top: 5vh;
    width: 100vw;
    background-image: linear-gradient(#4FA673, #DBBA4B, #FB492C87) ;
    display: flex;
    justify-content: center;
    flex-direction: column;   
    color: white;
}
.employment_welcome{
    margin: 5vh;
    background-color: #242424;
    height: auto;
    width: 60vw;
    border-radius: 12px;
    box-shadow: 11px 13px 10px 5px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;
    align-self: center;
    color: #4FA673;
    font-weight: 600;
    font-size: 12pt;
    padding: 5vh;
}
.employment_welcome h1{
    color: #DBBA4B;
    font-size: 26pt;
    font-weight: 700;
}
.employment_welcome p{
    padding: 1.5vw;
}
.employment_body{
    margin: 2rem;
    padding-top: 2rem;
    background-color: #24242433;
    width: 92vw;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-self: center;
    flex-direction: column;
}
.employment_body h1{
    font-size: 30pt;
    font-weight: 700;
    padding: 0.5rem;
    margin-bottom: 4rem;
}
.employment_body p{
  line-height: 1.8;
  padding: 1rem;
}
.employment_body h2{
    color: #4FA673;
    font-size: 22pt;
    font-weight: 700;
    padding: 0.5rem
}
.employment_body h3{
    color: #DBBA4B;
    font-size: 22pt;
    font-weight: 700;
    padding: 0.5rem
}
.employment_shovel{
    align-self: center;
    width: 90%;
    border-radius: 20px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    margin: 3rem;
}
.employment_plants{
    align-self: center;
    height: 40vh;
    width: 90%;
    border-radius: 20px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    object-fit: cover;
    margin: 2rem 0;
}
.todo{
    color: rgb(146, 23, 140);
}
.application{
    border: #242424 4px solid; 
    border-radius: 20px;
    width: 60vw;
    align-self: center;
    padding: 1rem;
    margin: 2rem;
    font-weight: 600;
}

.application_button{
    height: auto;
    width: auto;
    padding: 1rem 1.25rem;
    background-color: #DBBA4B;
    border: none;
    border-radius: 12px;
    font-family: 'Raleway';
    font-size: 12pt;
    font-weight: 600;
    color: white;
    align-self: center;
    cursor: pointer;
    margin: 1rem;
    box-shadow: 1px 3px 10px 5px rgba(0, 0, 0, 0.25);
}
.application_button:hover{
    background-color: #ffffff;
    color: #DBBA4B;;
    transition: 1s
}
.work_icon_outer{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    font-weight: 600;
}
.work_icon_item{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    aspect-ratio: 1;
}
.work_icon_item img{
    padding: 1rem;
    z-index: 4;
    position: relative;
}
.work_cal{
    height: 5rem ;
}
.work_community{
    height: 3.7rem ;
}
.work_sun{
    height: 5rem ;
}
.work_growth{
    height: 4.2rem ;
}

.work_icon_item {
    overflow: hidden;
    position: relative;
    display: inline-block;
  }
  
  .work_icon_item::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: #242424;
    transition: left 0.3s ease;
  }
  
  .work_icon_item:hover::before {
    left: 0;
  }
  
  .work_icon_item:hover {
    border-radius: 20px;
  }
  
  .work_icon_item:hover h4 {
    color: #4FA673;
    transition: color 0.3s ease;
    background-color: #242424;
  }
  
  .work_icon_item h4 {
    overflow: hidden;
    position: relative;
    display: inline-block;
  }
  
  
@media (min-width:1025px) { 
    .employment{
        font-size: 18pt;
    }
    .employment_welcome {
      width: 60%;
    }
    .employment_welcome h1{
        font-size: 60pt;
    }
    .employment_welcome p{
        font-size: 18pt;
    }
    .employment_body{
        width: 80vw;
    }
    .employment_body p{
        margin: 1rem 4rem;
    }
    .employment_body h2{
        font-size: 42pt;
    }
    .employment_body h3{
        font-size: 42pt;
    }
    .employment_top_header{
        margin: 2rem 10rem;
        text-align: center;
    }

    .work_icon_outer{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        font-weight: 600;
        font-size: 24pt;
    }

    .work_icon_item{
        margin: 2rem;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        aspect-ratio: 1;
        z-index: 4;
    }

    .work_icon_item img{
        padding: 4rem;
    }
    .work_cal{
        height: 10rem ;
    }
    .work_community{
        height: 8.7rem ;
    }
    .work_sun{
        height: 10rem ;
    }
    .work_growth{
        height: 8.4rem ;
    }

    .application_button{
        font-size: 18pt;
    }
}

@media (min-width:1400px) { 
    .work_sun{
        height: 6rem;
    }
    .work_community{
        height: 4.2rem;
    }
    .work_growth{
        height: 6.5rem
    }
    .work_cal{
        height: 6rem;
    }
    .work_icon_outer{
        display: flex;
        align-self: center;
        justify-content: space-evenly;
        font-weight: 600;
        font-size: 24pt;
    }


}

  
@media (min-width:1800px) { 
    .employment_plants{
        height: 55vh;
        width: 80%;
    }

}

@media (min-width:2025px) { 
    .employment_top_header{
        margin: 2rem 30rem;
        text-align: center;
    }
    
    .work_icon_outer{
        display: flex;
        align-self: center;
        justify-content: space-evenly;
        font-weight: 600;
        font-size: 24pt;
    }

    .work_icon_item{
        width: 25rem;
        margin: 2rem;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        aspect-ratio: 1;
    }

    .work_icon_item img{
        padding: 4rem;
    }

    .work_cal{
        height: 10rem ;
    }
    .work_community{
        height: 8.7rem ;
    }
    .work_sun{
        height: 10rem ;
    }
    .work_growth{
        height: 8.4rem ;
    }
    .employment_shovel{
        align-self: center;
        height: 40rem;
        object-fit: cover;
        width: 90%;
        border-radius: 20px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        margin: 3rem;
    }
    .application_button{
        font-size: 22pt;
    }



}


