
.nb_container{
    height: auto;
    width: 90vw;
    margin-top: 10vh;
    margin-bottom: 10vh;
    border-radius: 20px;
    box-shadow: 11px 13px 10px 5px rgba(0, 0, 0, 0.25);
    background-color: #24242433;
}

.nb_paragraph{
    margin-left: 5vw;
    margin-right: 5vw;
    padding: 2rem 1rem;
    line-height: 1.4;
}
.nb_button{
    height: auto;
    width: auto;
    background-color: #4FA673;
    border: none;
    border-radius: 12px;
    font-family: 'Raleway';
    font-size: 12pt;
    font-weight: 600;
    color: white;
    align-self: center;

    cursor: pointer;
    padding: 1rem;
    -webkit-box-shadow:0px 0px 47px 5px rgba(219,185,75,0.52);
-moz-box-shadow: 0px 0px 47px 5px rgba(219,185,75,0.52);
box-shadow: 0px 0px 47px 5px rgba(219,185,75,0.52);
}
.nb_button:hover{
    background-color: #ffffff;
    color: #4FA673 ;
    transition: 1s;
    
}
.blog_name{
    border-radius: 12px;
    font-weight: 700;   
    font-size: 26pt;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.abc{
    font-weight: 700;
}
.home_blog_card{
    width: 100%;
    max-width: 75vw; /* Set a maximum width for responsiveness */
    aspect-ratio: 1/1; 
    border-radius: 20px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.4));
    height: auto;
    object-fit: cover;
    margin: 2rem 0;
}  
.hpb_button{
    margin: 3rem 0;
}
.blog_intro{
    line-height: 1.8;
  
}
.home_blog_text{
    margin: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.blog_name{
   padding:  1.5rem 0.5rem;
}
.blog_intro_container{
    display: flex;
    justify-content: center;
}




@media (min-width:1024px) { 

    .nb_container{
        width: 70vw;
        font-size: 18pt;
    }
    .nb_container_outer h3{
        font-size: 26pt;
        padding: 1rem;
        font-weight: 600;
    }
.nb_button{
    font-size: 18pt;
    border-radius: 20px;
    white-space: nowrap;
    width: auto;
     padding: 1.25rem 1.5rem;
}
.home_blog_card{
    max-width: 650px;
    margin: 2rem ;
    }
 }


@media (min-width:1500px) { 

    .home_blog_card{
        max-width: 730px;
    }
    
    .home_blog_text{
        padding-top: 5rem;
    }



    .blog_card_container_img{
        display: flex;
        justify-content: center;
        margin: 4rem;
    }


.blog_card_container{
display: flex;
justify-content: space-evenly;
}

.blog_name{
   padding: 2.5rem;
}
.home_blog_text_title{
    align-self: center;
}
.blog_intro_container{
width: 30vw;
height: 20vh;
overflow: hidden;
margin: 0;
}


.blog_intro{
    overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 6; 
-webkit-box-orient: vertical;
}



 }





 @media (min-width:2000px) { 
.home_blog_card{
    max-width: 700px;
}
.home_blog_text{
    padding-top: 7rem;
}
.blog_intro_container{
    height: 30vh;
}
.blog_intro{
    -webkit-line-clamp: 9;
}


 }

