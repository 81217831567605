.Landscaping{
    height: auto;
    padding-top: 5vh;
    width: 100vw;
    background-image: linear-gradient(#95562E, #DB4B4B, #15F6C0a8  ) ;
    display: flex;
    justify-content: center;
    flex-direction: column;   
}
.landscaping_welcome{
    margin: 5vh;
    background-color: #242424;
    height: auto;
    width: 60vw;
    border-radius: 20px;
    box-shadow: 11px 13px 10px 5px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;
    align-self: center;
    color: #95562E;
    font-weight: 700;
    padding: 5vh;
}
.landscaping_welcome h2{
    color: #4FA673;
    font-size: 26pt;
    font-weight: 700;
}

.landscaping_body{
    background-image: linear-gradient( #9f4a38, #6f5d53, #209276);
    height: auto;
    width: 90vw;
    border-radius: 20px;
    box-shadow: 11px 13px 10px 5px rgba(0, 0, 0, 0.25);
   align-self: center;
   color: white;
   padding-bottom: 2rem;
   margin-bottom: 4rem;
   display: flex;
   justify-content: center;
   flex-direction: column;
}

.landscaping_body p{
    margin: 1rem;
    color: white;
    line-height: 1.8;
}

.landscaping_img{
    height: 50vh;
    width: 85vw;
    object-fit: cover;
    border-radius: 20px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.4));
    align-self: center;
    margin: 2rem;
}
.landscaping_body h2{
    font-size: 20pt;
    text-decoration: underline;
    margin-bottom: 1rem;
}



@media (min-width:1025px) {

    .landscaping_welcome h2{
        font-size: 60pt;
    }
    .landscaping_body p{
        font-size: 18pt;
        margin: 3rem;
    }
    .landscaping_img{
        margin: 4rem;
}
.landscaping_body h2{
    font-size: 34pt;
    text-decoration: underline;
    margin-bottom: 2rem;
}


}

@media (min-width:2025px) {
    .landscaping_img{
        height: 70vh;
    }
.landscaping_body p{
    margin: 4rem 6rem;
}


}

@media screen 
  and (min-width: 1000px) 
  and (max-height: 900px)  { 

.landscaping_img{
    height: 85vh;

}

}

