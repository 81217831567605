.TypesofPlants{
    height: auto;
    padding-top: 5vh;
    width: 100vw;
    background-image: linear-gradient(#95562E, #DB4B4B, #15F6C0a8  ) ;
    display: flex;
    justify-content: center;
    flex-direction: column;   
}
.TypesofPlants p{
    line-height: 1.8;
}
.TypesofPlants h3{
    font-size: 30pt;
    padding: 1.5rem;
}
.top_welcome{
    margin: 5vh;
    background-color: #242424;
    height: auto;
    width: 60vw;
    border-radius: 20px;
    box-shadow: 11px 13px 10px 5px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;
    align-self: center;
    color: #95562E;
    font-weight: 700;
    padding: 5vh;
}
.top_welcome h1{
    color: #4FA673;
    font-size: 26pt;
    font-weight: 700;
}
.top_welcome p{
    padding: 1.5vw;
    line-height: 1.4;
}
.top_body_main{
    background-image: linear-gradient( #9f4a38, #6f5d53, #209276);
    height: auto;
    width: 90vw;
    border-radius: 20px;
    box-shadow: 11px 13px 10px 5px rgba(0, 0, 0, 0.25);
   align-self: center;
   color: white;
   padding-bottom: 2rem;
   margin-bottom: 4rem;
   display: flex;
   justify-content: center;
}
.top_body_main h2{
    font-size: 20pt;
    font-weight: 700;
    padding: 1rem;
}
.type_of_image{
    height: 35vh;
    width: 65vw;
    object-fit: cover;
    border-radius: 20px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.4));
    align-self: center;
    margin-bottom: 2rem;
}

.top_card{
    border-radius: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 1rem;
    border-top: #DBBA4B 8px solid;
    cursor: pointer;
}
.top_card:hover{
    filter: drop-shadow(0px 8px 8px rgba(0, 0, 0, 0.4));
}








@media (min-width:1025px) {

    .top_welcome h1{
        font-size: 60pt;
    }
    .top_welcome p{
        font-size: 22pt;
    }
    .top_body_main h2{
        font-size: 30pt; 
    }

    .type_of_image{
        width: 45vw;
    }

}

@media (min-width:2025px) {

    .type_of_image{
        height: 55vh;
        width: 40vw;
    }
    .top_body_main h2{
        font-size: 40pt; 
    }
}

@media screen 
  and (min-width: 1000px) 
  and (max-height: 900px)  { 

.type_of_image{
    height: 75vh;
    width: 45vw;
}

}

