
#top_smoke{
animation: smoky;
animation-duration: 2s;
}
#mid_smoke{
 animation: smoky2;
  animation-duration: 2s;
}
#road{
  animation: road;
  animation-duration: 2s;
}

  
   #front_wheel{
    transform-box: fill-box;
    transform-origin: 50% 50%;
    animation-duration: 3s;
    animation-name: rotate;

   }
   #back_wheel{
    transform-box: fill-box;
    transform-origin: 50% 50%;
    animation-duration: 3s;
    animation-name: rotate;

   }
   @keyframes smoky {
    0% {
      transform: translateX(-95px) ;
      opacity: 0.8;
    }
    50% {
      transform: translateX(50px);
      opacity: 0.5;
    }
    100%{
      transform: translateX(0px);
      opacity: 1;
    }
  }
   @keyframes smoky2 {
    0% {
      transform: translateY(-75px) ;
      opacity: 0.8;
    }
    50% {
      transform: translateY(30px);
      opacity: 1;
    }
    100%{
      transform: translateY(0px);
      opacity: 1;
    }
  }
  @keyframes road {
    0% {
      transform: scaleX(1);
    }
    20% {
      transform: scaleX(.5);
    }
    50% {
      transform: scaleX(1.1);
    }
    100% {
      transform: scaleX(1);
    }
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(2160deg);
    }
  }
