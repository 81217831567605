.home{
    width: 100vw;
    background-image: linear-gradient(#4FA673, #DBBA4B , #FB492C87) ;
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.welcome{
    height: auto;
    width: 96vw;
    box-shadow: 11px 13px 10px 5px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-self: center;
    margin: 5vh;
    margin-top: 10vh;
    margin-bottom: 5vh;
    background-color: #242424;
    padding-bottom: 6vh;
    overflow-wrap: break-word;
}
.welcome p{
    line-height: 1.8;
}
.w{
    display: flex;
    flex-direction: column;
    align-self: center;

    white-space: nowrap;
}
.w_1{
    margin-top: 4vh;
    margin-left: -11rem;
    color: #4FA673;
    font-size: 1.1667em;
    font-weight: 600;
}
.w_2{
    font-size: 2.3em;
    font-weight: 700;
    color: #DBBA4B;
   align-self: center;
}
.w_3{
    margin-left: 8vw;
    margin-top: 1vh;
    color: #4FA673;
    font-size: 1.1667em;
    font-weight: 600;
    overflow: hidden;
    padding-bottom: .1rem;
}

.p_1{
    color: white;
    font-weight: 500;
    font-size: 12pt;
    margin-top: 2vh;
    margin: 2vw 4vw;
    margin-bottom: -2vh;
}

.hours_and{
    display: flex;
    justify-content: space-around;
    padding-top: 3rem;
}

.phone_button{
    height: auto;
    width: auto;
    padding: 1rem 1.25rem;
    background-color: #4FA673;
    border: none;
    border-radius: 12px;
    font-family: 'Raleway';
    font-size: 12pt;
    font-weight: 600;
    color: white;
    align-self: center;
    cursor: pointer;
    margin: 1rem;
    box-shadow: 1px 3px 10px 5px rgba(0, 0, 0, 0.25);
}
.phone_button:hover{
    background-color: #ffffff;
    color: #4FA673;
    transition: 1s
}
.map_button_home{
    height: auto;
    width: auto;
    padding: 1rem 1.25rem;
    background-color: #DBBA4B;
    border: none;
    border-radius: 12px;
    font-family: 'Raleway';
    font-size: 12pt;
    font-weight: 600;
    color: white;
    align-self: center;
    cursor: pointer;
    margin: 1rem;
    box-shadow: 1px 3px 10px 5px rgba(0, 0, 0, 0.25);
}
.map_button_home:hover{
    background-color: #ffffff;
    color: #DBBA4B;
    transition: 1s
}
.hours_button{
    height: auto;
    width: auto;
    padding: 1.75rem 1.25rem;
    background-color: #95562e;
    border: none;
    border-radius: 12px;
    font-family: 'Raleway';
    font-size: 12pt;
    font-weight: 600;
    color: white;
    align-self: center;
    cursor: pointer;
    margin: 1rem;
    box-shadow: 1px 3px 10px 5px rgba(0, 0, 0, 0.25);
}
.hours_button:hover{
    background-color: #ffffff;
    color: #95562e;
    transition: 1s
}
.banner_one{
margin-top: 3rem;
}
.weather_warning{
    color: white;
    font-weight: 700;
    background-color: #242424;
    padding: 2rem;
}
.desktop_hours_and{
    display: none;
}

@media (min-width:1025px) { 
.welcome{
    width: 60%;
}
.w{
    display: flex;
    flex-direction: column;
    align-self: center;
    width: auto;
    word-wrap: break-word;
}
.w_1{
    font-size: 26pt;
    margin-left: -30rem;
    padding-left: 5rem;
}
.w_2{
    font-size: 60pt;
}
.w_3{
    font-size: 26pt;
    margin-left: 5vw;
}
.p_1{
    font-size: 18pt;
    line-height: 1.4;
}
.hours_and{
    display: none;
}
.desktop_hours_and{
    display: flex;
    justify-content: center;
}
.desktop_hours_and h3{
    margin: 1rem;
    font-size: 16pt;
    color: #242424;
    text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.5);
 }
}
 @media (min-width:1281px) { 
    .w_1{
        padding-left: 2rem;
    }
    .w_3{
        margin-left: 15vw;
    }
  }
  @media (min-width: 2200px) {
    .w_1{
        margin-left: -40rem;
    }
    .welcome{
        width: 40%;
    }

  } 