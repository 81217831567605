.not_found{
    height: auto;
    background-color: #242424;
    color: white;
    font-size: 14pt;
    font-weight: 800;
    line-height: 2;
    display: flex;
    flex-direction: column;
}
.not_found h2{
font-size: 22pt;
text-decoration: underline;
margin: 2rem;
}
.not_found p{
    margin: 4rem;
}
.four_o{
height: 40vh;
width: auto;
align-self: center;
}
.fof{
 
        height: auto;
        width: auto;
        padding: 1rem 1.25rem;
        background-color: #4FA673;
        border: none;
        border-radius: 12px;
        font-family: 'Raleway';
        font-size: 12pt;
        font-weight: 600;
        color: white;
        align-self: center;
        cursor: pointer;
        margin: 1rem;
        box-shadow: 1px 3px 10px 5px rgba(0, 0, 0, 0.25);
}
.fof:hover{
    background-color: #ffffff;
    color: #4FA673;
    transition: 1s
}

@media (min-width:1025px) { 

    


}

