@keyframes swirl {
    0% {
      transform: rotate(0deg) translate(100px) rotate(0deg)
    }
    100% {
      transform: rotate(360deg) translate(0px) rotate(-360deg);
      animation-timing-function: ease-in 1s;
    }
  }
@keyframes swirl2 {
    0% {
      transform: rotate(0deg) translate(200px) rotate(0deg)
    }
    100% {
      transform: rotate(-360deg) translate(0px) rotate(360deg);
      animation-timing-function: ease-in 1s;
    }
  }
  @keyframes swirl3 {
    0% {
      transform: rotate(0deg) translate(150px) rotate(0deg);
    }
    100% {
      transform: rotate(360deg) translate(100px) rotate(-360deg);
      animation-timing-function: ease-in 1s;
    }
  }
  @keyframes wave {
    0% {
      opacity: 0;
      transform: translateY(0);
    }
    50% {
      opacity: 1;
      transform: translateY(-30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
#yellow_dot_1{
    fill: #dbba4b;
    animation: swirl;
    animation-duration: 2s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
}
#yellow_dot_2{
    fill: #dbba4b;
    animation: swirl;
    animation-duration: 2s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
}
#green_dot_1{
    fill: #4fa673;
    animation: swirl2;
    animation-duration: 2s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
}
#green_dot_2{
    fill: #4fa673;
    animation: swirl2;
    animation-duration: 2s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
}
#brown_dot_1{
    fill: #95562e;
    animation: swirl3;
    animation-duration: 1.5s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
}
#brown_dot_2{
    fill: #95562e;
    animation: swirl2;
    animation-duration: 2s;
    animation-timing-function: ease-in;

}



#yellow_wave{
stroke: #dbba4b;
animation: wave;
animation-duration: 1s;
animation-timing-function: ease-in;
animation-fill-mode: forwards;
animation-delay: 1s;
fill-opacity: 0;
}
#green_wave{
stroke: #4fa673;
animation: wave;
animation-duration: 1s;
animation-timing-function: ease-in;
animation-fill-mode: forwards;
animation-delay: .5s;
}
#brown_wave{
    stroke: #95562e;
    animation: wave;
    animation-duration: 1s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
}
.invis{
    opacity: 0;
}

