.Single_Blog_Post{
    padding-top: 5vh;
    background-image: linear-gradient(#4FA673, #DBBA4B , #FB492C87 ) ;
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    line-height: 2;
}
.Single_Blog_Post p{
    margin: 2rem 10rem;
}

.single_blog_welcome{
    height: auto;
    width: 50vw;
    margin: 5vh;
    margin-top: 10vh;
    padding-bottom: 2rem;
    padding: 5vh;
    background-color: #242424;
    border-radius: 20px;
    box-shadow: 11px 13px 10px 5px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-self: center;
    color:  #a0d687;
    font-weight: 600;
    font-size: 30pt;
    line-height: 2;
}


.blog_welcome h2 {
   padding-top: 5rem;
}

.blog_body_main{
    background-color: #24242444;
    height: auto;
    width: 90vw;
    border-radius: 20px;
    box-shadow: 11px 13px 10px 5px rgba(0, 0, 0, 0.25);
   align-self: center;
   color: white;
   margin-bottom: 15rem;
}
.blog_body_main p{
    font-size: 18pt;
    padding: 3rem 2rem;
}
.blog_title{
    font-size: 38pt;
    font-weight: 700;
    padding-top: 2rem;
    color: #DBBA4B;
}
.blog_body_mid h1{
    font-size: 45pt;
    font-weight: 700;
    padding: 2rem;
    color: #95562E;
}



.blog_img{
    border-radius: 20px;
    box-shadow: 11px 13px 10px 5px rgba(0, 0, 0, 0.25);
    height: auto;
    width: 50vw;
    align-self: center;
    object-fit: cover;
}

.blog_img_1{
    border-radius: 20px;
    box-shadow: 11px 13px 10px 5px rgba(0, 0, 0, 0.25);
    height: 75vh;
    max-width: 85vw;
    align-self: center;
    object-fit: cover;
}


.left_8 img{
    width: 50vw;
}
.right_9 img{
    width: 50vw;
}


.blog_body_top{
    display: flex;
    justify-content: center;
    flex-direction: column;
}


.blog_body_top button{
    height: 5vh;
    width: 15vh;
   background-color: #DBBA4B;
    border: none;
    border-radius: 20px;
    font-family: 'Raleway';
    font-size: 18pt;
    font-weight: 600;
    color: white;
    align-self: center;
    margin-top: 1rem;
    margin-bottom: 3vh;
    cursor: pointer;

}
.blog_body_top button:hover{
    background-color: #ffffff;
    color: #DBBA4B;
    transition: 1s;
}

.blog_body_mid button{
    height: 5vh;
    width: 15vh;
   background-color: #4FA673;
    border: none;
    border-radius: 20px;
    font-family: 'Raleway';
    font-size: 18pt;
    font-weight: 600;
    color: white;
    align-self: center;
    margin-top: 1rem;
    margin-bottom: 3vh;
    cursor: pointer;
}

.blog_body_mid button:hover{
    background-color: #ffffff;
    color: #4FA673;
    transition: 1s;
}



.blog_paragraph_top{
    display: flex;
    flex-direction: column;
}
.blog_paragraph_mid{
    display: flex;
    flex-direction: column;
}

.blog_paragraph_bottom{
    display: flex;
    flex-direction: column;
}

.blog_body_bottom{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 5rem;
}

.span_green{
    color: #4FA673;
    font-size: 40pt;
    font-weight: 700;
    padding: 1rem;
    background-color: #242424;
    margin-bottom: 2rem;
}

.span_yellow{
    color: #DBBA4B;
    font-size: 40pt;
    font-weight: 700;
    padding: 1rem;
    background-color: #242424;
}
.span_brown{
    color: #95562E !important;
    font-size: 40pt;
    font-weight: 700;
    padding: 1rem;
    background-color: #242424;
}
.date{
    color: rgb(207, 207, 207);
    padding-bottom: 3rem;
}


.blog_icon_1{
display: flex;
justify-content: center;
align-self: center;
}
.blog_icon_1 img{
    height: 10rem;
    width: auto;
    object-fit: cover;
    padding: 2rem;
}
.blog_icon_2{
display: flex;
justify-content: center;
align-self: center;
}
.blog_icon_2 img{
    height: 10rem;
    width: auto;
    object-fit: cover;
}
.blog_icon_3{
display: flex;
justify-content: center;
align-self: center;
padding: 2rem;
}
.blog_icon_3 img{
    height: 10rem;
    width: auto;
    object-fit: cover;
}

.left_8{
    display: flex;
    padding: 2rem;
}
.right_9{
    display: flex;
    flex-direction: row-reverse;
    padding: 2rem;
}
.left_8 p{
    padding: 1rem ;
    margin: 2.5rem !important;
    align-self: center;
}
.right_9 p{
    padding: 1rem ;
    margin: 2.5rem !important;
    align-self: center;
}
.blog_bold{
    font-size: 26pt !important;
    font-weight: 700;
    text-shadow: 2px 2px 4px rgba(0,0,0,.5);
}
.ql-editor{
    margin: 2rem 10rem !important;
    text-align: center !important;
    line-height: 2 !important;
    padding: 3rem 2rem;
}

@media (max-width: 1600px){
    .blog_img{
        width: 60vw;
    }

}


@media screen and (max-width: 1200px),
screen and (max-height: 650px){
    .Single_Blog_Post button{
        height: auto;
        width: auto;
        padding: 1rem;
    }
    .blog_bold{
        font-size: 16pt !important;
        font-weight: 700;
    }
}


@media (max-width: 1050px){
    .left_8{
        flex-direction: column;
    }
    .right_9{
        flex-direction: column;
    }
    .blog_bold{
        font-size: 16pt !important;
        font-weight: 700;
    }
}

@media (max-width: 750px){
    .single_blog_welcome{
        font-size: 18pt;
    }
    .blog_title{
        font-size: 26pt;
    }
    .blog_body_main{
        background-color: #24242444;
        height: auto;
        width: 90vw;
        border-radius: 20px;
        box-shadow: 11px 13px 10px 5px rgba(0, 0, 0, 0.25);
       align-self: center;
       color: white;
       margin-bottom: 15rem;
    }
    .Single_Blog_Post p{
        font-size: 12pt;
        margin: 0;
    }
    .ql-editor{
        margin: 0 !important;
    }
    .Single_Blog_Post button{
        font-size: 12pt;
        border-radius: 12px;
    }
    .blog_bold{
        font-size: 16pt !important;
        font-weight: 700;
    }
    .blog_img_1{
        border-radius: 20px;
        box-shadow: 11px 13px 10px 5px rgba(0, 0, 0, 0.25);
        height: auto;
        width: 80vw;
        align-self: center;
        object-fit: cover;
    }
    
    .blog_img{
        border-radius: 20px;
        box-shadow: 11px 13px 10px 5px rgba(0, 0, 0, 0.25);
        height: auto;
        width: 80vw;
        align-self: center;
        object-fit: cover;
    }

    .body_img_1{
        border-radius: 20px;
        box-shadow: 11px 13px 10px 5px rgba(0, 0, 0, 0.25);
        height: auto;
        width: 85vw;
        align-self: center;
        margin: 2rem;
    }
    .body_img_2{
        border-radius: 20px;
        box-shadow: 11px 13px 10px 5px rgba(0, 0, 0, 0.25);
        height: auto;
        width: 85vw;
        align-self: center;
        margin: 2rem;
    }
    .body_img_3{
        border-radius: 20px;
        box-shadow: 11px 13px 10px 5px rgba(0, 0, 0, 0.25);
        height: auto;
        width: 85vw;
        align-self: center;
        margin: 2rem;
    }
    .body_img_4{
        border-radius: 20px;
        box-shadow: 11px 13px 10px 5px rgba(0, 0, 0, 0.25);
        height: auto;
        width: 85vw;
        align-self: center;
        margin: 2rem;
    }

        .blog_icon_1 img{
            height: 8rem;
        }

        .blog_icon_2 img{
            height: 8rem;

        }

        .blog_icon_3 img{
            height: 8rem;
        }
        .span_yellow{
            font-size: 30pt;
        }
        
        .span_green{
            font-size: 30pt;
        }
        
        .span_brown{
            font-size: 30pt;
        }
        .blog_body_mid h1{
            font-size: 30pt;
        }
        .left_8 p{
            padding: 3rem 1rem ;
            margin: 0 !important;
        }   
        .right_9 p{
            padding: 3rem 1rem ;
            margin: 0 !important;
        
        }
        

}


@media screen 
  and (min-width: 900px) 
  and (max-height: 900px)  { 
   .left_8 p{
    margin: 0 !important;
   }
   .right_9 p{
    margin: 0 !important;
   }


}