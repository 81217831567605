.newGalleryImg{
    background-color: #242424;
    color: white;
    padding: 2rem 10rem;
}
.newGalleryImg h1{
font-size: 24pt;
font-weight: 700;
}
.newGalleryImg h2{
font-size: 20pt;
font-weight: 600;
color: #6b83a8;
padding: 2rem;
text-decoration: underline;
}

.newGalleryImg button{
    height: auto;
    width: auto;
    padding: 0.5rem 1.25rem;
   background-color: #2a4c80;
    border: none;
    border-radius: 20px;
    font-family: 'Raleway';
    font-size: 18pt;
    font-weight: 600;
    color: white;
    align-self: center;
    margin-top: 1rem;
    margin-bottom: 3vh;
    cursor: pointer;
}

.newGalleryImg button:hover{
    background-color: #ffffff;
    color: #2a4c80;
    transition: 1s;
}

.newGallery_form form{
display: flex;
justify-content: center;
flex-direction: column;
}

.newGallery_form input{
padding: 1rem;
margin: 2rem;
}