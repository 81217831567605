.ffc{
    height: auto;
    padding-top: 5vh;
    width: 100vw;
    height: auto;
    background-image: linear-gradient(#dbba4b,#4ba7db,#db4bd5cf);
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
}
.ffc_welcome{
    margin: 5vh;
    background-color: #242424;
    height: auto;
    width: 65vw;
    border-radius: 20px;
    box-shadow: 11px 13px 10px 5px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;
    align-self: center;
    color:  #DBBA4B;
    font-weight: 600;
    font-size: 12pt;
    padding: 3vh;
    z-index: 2;
}
.ffc_welcome h1{
    color: white;
    font-size: 26pt;
    font-weight: 900;
    -webkit-text-stroke: 1px white;

}
.ffc_welcome p{
    color: white;
    margin-top: 1rem;
    padding: 1.5vw;
}
.ffc_body{
    background-image: linear-gradient(#b19c4a, #7271b7) ;
    height: auto;
    width: 95vw;
    display: flex;
    justify-content: center;
    align-self: center;
    flex-direction: column;
    margin-bottom: 10vh;
    border-radius: 20px;
    z-index: 2;
}
.ffc_body_top p{
    color: white;
    padding: 1rem;
    font-size: 12pt;
    position: relative;
    z-index: 2;
    text-align: center;
    line-height: 1.8;
}
.w_it_is{
    font-size: 26pt;
    font-weight: 700;
    color: #106D36;
    padding: 3vh;
    display: flex;
    margin-left: 4vw;
}
.ffc3{
    height: auto;
    width: 60vw;
    position: absolute;
    left: 15vw;
    z-index: 1;
    opacity: 75%;
}
.ffc_body_bottom h2{
    font-size: 20pt;
    padding: 1rem;
    font-weight: 700;
    color: white;
    z-index: 3;

}
.ffc_body_bottom li{
    line-height: 3;
    font-weight: 700;
    font-size: 12pt;
    color: white;
    z-index: 3;
    padding: 0.5rem;
 
}
.ffc_sunflowers{
    margin: 3vw;
    height: 40vh;
    width: 80vw;
    position: relative;
    z-index: 2;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 20px;
    object-fit: cover;
}
.ffc_body_bottom{
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 2vh;
}
.ffc_body_bottom p{
    color: white;
    padding: 0 10vw;
    font-size: 12pt;
    position: relative;
    z-index: 2;
    text-align: center;
    line-height: 1.8;
}
.w_you_get{
    font-size: 26pt;
    font-weight: 700;
    color: #EDB592;
    padding: 3vh;
    display: flex;
    margin-bottom: 1rem;
    margin-left: 4vw;
    position: relative;
    z-index: 2;
}
.ffc_arches{
    height: 55vh;
    width: 75vw;
   object-fit: cover;
   border-radius: 20px;
   filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
   margin: 0;
   align-self: center;
}
.b_h{
    display: flex;
    flex-direction: column;
}
.b_h_list{
    z-index: 3;
}
.ffc3_s{

    position: relative;
    height: auto;
    width: 45vw;
    z-index: 0;
    opacity: 75%;
    transform: rotate(180deg);
}
.h_to_join{
    font-size: 26pt;
    font-weight: 700;
    color: #533C71;
    padding: 3vh;
    display: flex;
    margin-left: 4vw;
}
.ffc_form_container_2 h3{
    font-weight: 700;
    font-size: 16pt;
    color: white;
    display: flex;
    justify-content: center;
    padding-bottom: 4rem;
}
.f_f{
    display: flex;
    justify-content: center;
}
.f_f button{
    height: auto;
    width: auto;
    padding: 1rem 1.25rem;
    background-color: #4FA673;
    border: none;
    border-radius: 12px;
    font-family: 'Raleway';
    font-size: 12pt;
    font-weight: 600;
    color: white;
    align-self: center;
    cursor: pointer;
    margin: 1rem;
    box-shadow: 1px 3px 10px 5px rgba(0, 0, 0, 0.25);

}
.f_f button:hover{
    background-color: #ffffff;
    color: #4FA673;
    transition: 1s
}
/* 
/////////////////// */
.jericho_ffc_flower{
    width: 90vw;
    border-radius: 12px;
    margin-bottom: 2rem;
}
.ffc_body_top h1{
    margin: 1rem;
    margin-top: 2rem;
    font-size: 25pt;
    font-weight: 800;
    color: white;
    -webkit-text-stroke: 1px white;
}
.ffc_intro_paragraph{
    font-weight: 500;
}
.ffc_grid-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
.ffc_grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    max-width: 400px;
    padding: 1rem;
    align-items: center;
  }
  
  
  .ffc_grid-item {
    display: flex;
    color: white;
    font-weight: 700;
    margin: 0.75rem;

  }
  
  .ffc_icon {
    width: auto; 
    height: 24px;
    margin-right: 8px; /* Space between icon and text */
  }

  .ffc_sign_up button{
    margin: 1rem;
    border: none;
    color: white;
    background-color: #8BC34A;
    border-radius: 10px;
    padding: 0.5rem 1.2rem;
    font-size: 16pt;
    font-weight: 700;
    cursor: pointer;
  }
  .ffc_sign_up button:hover{
    background-color: white;
    color: #8BC34A;
    transition: 1s;
  }

  .icon_background_container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
  }
  
  .icon_background_container img {
    position: absolute;
    z-index: 1; 
    overflow: hidden;
  }
  
  .ffc_iconf {
    width: 40vw;
    top: 450px;
    left: 20px;
  }
  
  .ffc_iconf1 {
    width: 50vw;
    left: 270px;
    top: 260px;
  }
  
  .ffc_iconf2 {
    width: 60vw;
    top: 160px;
    left: -10px;
  }
  .newffc_logo{
    width: 18vw;
    height: 18vw;
  }
  .newffc_container{
    display: flex;
    justify-content: center;
  }

  .ffc_intro_paragraph h2{
    color: white;
    font-size: 20pt;
    margin: 2rem;
    font-weight: 800;
  }

  .ffc_rewards{
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .rewards{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    margin: 4rem;
  }

  .ffc_rewards h3{
    font-weight: 600;
    font-size: 20pt;
    background-color: #242424;
    color: white;
    padding: 1rem 0rem;
    /* height: 1vh; */
    width: 75vw;
    margin-bottom: 2rem;
    border-radius: 10px;
  }
  .rewards h6{
    font-size: 16pt;
    font-weight: 700;
    padding: 0.5rem;
  }
  .rewards span{
    font-style: italic;
    font-size: 10pt;
  }
  .plant_ffc{
    width: 20vw;
  }
  .ffc_purchase{
    width: 20vw;
  }
  .earnings{
    margin: 1.5rem;
  }
.earnings img{
    width: 15vw;
}
.ffc_referal{
    border: none;
    margin: 1rem;
    padding: .5rem 1rem;
    border-radius: 10px;
    font-size: 8pt;
    background-color: #4ba7db;
    color: white;
    cursor: pointer;
}
.ffc_referal:hover{
    transition: 1s;
    color: #4ba7db;
    background-color: white;
}
.ffc_join{
    font-weight: 600;
    font-size: 20pt;
    background-color: #242424;
    color: white;
    padding: 1rem 0rem;
    width: 75vw;
    margin-bottom: 2rem;
    border-radius: 10px;
}
.ffc_sign_up{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.ffc_sign_up span{
    color: white;
    font-style: italic;
    font-size: 14pt;
    margin: 1rem 2rem;
}
.refer_earning{
    display: flex;
    flex-direction: column;
}


/* ///////////////////// */

@media (min-width:725px) { 
    .ffc_welcome{
        flex-direction: row;
    }
    .welcome_text{
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .newffc_logo{
        padding: 1rem;
    }
    .ffc_rewards h3{
        font-size: 24pt;
    }
    .plant_ffc{
        width: 16vw;
    }
    .ffc_purchase{
        width: 14vw;
    }
    .earnings img{
        width: 12vw;
    }
}






@media (min-width:1025px) { 
    .ffc_sign_up button{
        font-size: 24pt;
        margin: 4rem;
        padding: 1rem 2rem;
    }

    .ffc_grid-item{
        font-size: 18pt;
    }
    .ffc_grid-container{
        max-width: 50vw;
        gap: 50px;
    }
    .ffc_icon{
        height: 50px;
    }

    .ffc_body_top h1{
        font-size: 62pt;
        padding: 2rem;
    }

    .ffc_iconf{
        width: 20vw;
        top: 1050px;
        left: 100px;
    }
    .ffc_iconf1{
        width: 30vw;
        left: 890px;
        top: 680px;
    }
    .ffc_iconf2{
        width: 35vw;
        top: 400px;
    }
   
    .ffc_welcome{
        font-size: 18pt;
    }
    .ffc_welcome h1{
        font-size: 50pt;
        font-weight: 700;
        padding-bottom: 1rem;
    }
    .ffc_welcome p{
        padding: 1.5vw;
    }

    .ffc_body_top p{
        font-size: 18pt;
        line-height: 1.8;
        padding: 4rem 10rem;
    }
    .w_it_is{
        font-size: 60pt;
        padding-top: 4rem;
    }
    .ffc_body_bottom h2{
        font-size: 26pt;
        font-weight: 700;
    }
    .ffc_body_bottom li{
        font-size: 18pt;
        padding: 0.5rem;

    }
    .w_you_get{
        font-size: 60pt;
    }
    .ffc_arches{
        height: 70vh;
        width: 50vw;
        margin: 4rem;
    }
    .ffc_sunflowers{
        height: 50vh;
        width: 70vw;
        margin: 0rem;
    }
    .ffc3_s{
        position: relative;
        width: 30vw;
        padding: 1rem;
  
    }
    .b_h{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
    .b_h_list{
        z-index: 3;
        margin-top: 10rem;
        padding-left: 5rem;
    }
    .h_to_join{
        font-size: 60pt;
    }
    .ffc_form_container h3{
      font-size: 24pt;
    }
    .f_f button{
        font-size: 18pt;
    }
    .header_container{
        display: flex;
        justify-content: center;
    }
    .rewards_inner_horizantal{
        display: flex;
        justify-content: space-evenly;
    }
    .reward_1{
        margin: 1rem 5rem;
    }
    .reward_2{
        margin: 1rem 5rem;
    }
    .earning_detials_inner{
        display: flex;
    }
    .earning_horizantal{
        display: flex;
        justify-content: space-evenly;
    }

    .earning_details_inner{
        margin: 4rem;
    }
    .ffc_intro_paragraph h2{
        font-size: 26pt;
    }
    .rewards h6{
        font-size: 22pt;
    }
    .rewards span{
        font-size: 12pt;
    }
    .earning_details h4{
        font-size: 16pt;
        padding: 0.5rem;
    }
    .ffc_referal{
        font-size: 12pt;
    }
    .plant_ffc{
        width: 14vw;
    }
    .ffc_purchase{
        width: 12vw;
    }
    .earnings img{
        width: 10vw;
    }



}



@media (min-width:1200px) { 
    .ffc {
        height: auto;
        padding-top: 10vh;
    }

    .jericho_ffc_flower{
        width: 80vw;
    }
    .newffc_logo{
        width: 10vw;
        height: 10vw;
    }
    .plant_ffc{
        width: 12vw;
    }
    .ffc_purchase{
        width: 10vw;
    }
    .earnings img{
        width: 8vw;
    }
}
@media (min-width:1400px) { 
    .plant_ffc{
        width: 10vw;
    }
    .ffc_purchase{
        width: 8vw;
    }
    .earnings img{
        width: 6vw;
    }

}


@media (min-width:1600px) { 
    .jericho_ffc_flower{
        width: 70vw;
    }
    .ffc_body_top h1{
        width: 70vw;
        align-self: center;
    }
    .ffc_grid-container{
        max-width: 30vw;
    }
    .plant_ffc{
        width: 8vw;
    }
    .ffc_purchase{
        width: 6vw;
    }
    .earnings img{
        width: 4vw;
    }
}


@media (min-width:2000px) { 
    .jericho_ffc_flower{
        width: 60vw;
    }
    .plant_ffc{
        width: 6vw;
    }
    .ffc_purchase{
        width: 5vw;
    }
    .earnings img{
        width: 3.5vw;
    }
    .ffc_body_top p{
        padding: 4rem 20rem;
    }
    


}