@keyframes swirl {
    0% {
      transform: rotate(0deg) translate(100px) rotate(0deg)
    }
    100% {
      transform: rotate(360deg) translate(0px) rotate(-360deg);
      animation-timing-function: ease-in 1s;
    }
  }
  @keyframes swirl2 {
    0% {
      transform: rotate(0deg) translate(200px) rotate(0deg)
    }
    100% {
      transform: rotate(-360deg) translate(0px) rotate(360deg);
      animation-timing-function: ease-in 1s;
    }
  }
  @keyframes swirl3 {
    0% {
      transform: rotate(0deg) translate(150px) rotate(0deg);
    }
    100% {
      transform: rotate(360deg) translate(100px) rotate(-360deg);
      animation-timing-function: ease-in 1s;
    }
  }

  @keyframes fadeIn{
    from {
      opacity: 0; 
    }
    to {
      opacity: 1; 
    }
  }

#yellow_dots_s_1{
 fill: #dbba4b;
 animation: swirl;
 animation-duration: 2s;
 animation-timing-function: ease-in;
 animation-fill-mode: forwards;
}
#small_dots{
    fill: #95562E;
    animation: swirl2;
    animation-duration: 2s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
}
#more_dots{
    fill: #4fa673;
    animation: swirl3;
    animation-duration: 2s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
}
#background_si{
    fill: #95562E;
    animation: wave6 2s  ;
}
#seminar_icon{
  animation: fadeIn 2s;
}

  @keyframes wave6 {
0%{
  transform: translateX(-100%);
}
    25% {
        transform: translateX(-45%);
    }

    50% {
        transform: translateX(-50%);
    }

    100% {
        transform: translateX(1);
    }
}

