.home_container_outer{
    display: flex;
     justify-content: center;
     color: white;
     font-size: 12pt;
     margin: -2rem;
}
.home_container_outer h3{
    font-size: 22pt;
    padding: 1rem;
    font-weight: 600;
}
.home_container{
    height: auto;
    width: 90vw;
    margin-top: 10vh;
    margin-bottom: 10vh;
    border-radius: 20px;
    box-shadow: 11px 13px 10px 5px rgba(0, 0, 0, 0.25);
    background-color: #24242433;
}
.c_icon{
    height: 8vh;
    width: auto;
}
.c_image{
    height: 40vh;
    width: 80vw;
    border-radius: 20px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.4));
    object-fit: cover;
}
.hc_paragraph{
    margin-left: 5vw;
    margin-right: 5vw;
    padding: 2rem 1rem;
    line-height: 1.4;
}
.hc_button{
    height: auto;
    width: auto;
    background-color: #DBBA4B;
    border: none;
    border-radius: 12px;
    font-family: 'Raleway';
    font-size: 12pt;
    font-weight: 600;
    color: white;
    align-self: center;
    margin-top: 3vh;
    cursor: pointer;
    padding: 1rem;
    -webkit-box-shadow:0px 0px 47px 5px rgba(219,185,75,0.52);
-moz-box-shadow: 0px 0px 47px 5px rgba(219,185,75,0.52);
box-shadow: 0px 0px 47px 5px rgba(219,185,75,0.52);
}
.hc_button:hover{
    background-color: #ffffff;
    color: #DBBA4B ;
    transition: 1s;
}

.home_sale_card{
    width: 100%;
    max-width: 75vw; /* Set a maximum width for responsiveness */
    aspect-ratio: 1/1; 
    border-radius: 20px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.4));
    height: auto;
    object-fit: cover;
    margin: 2rem ;

}


@media (min-width:1025px) { 
.home_container{
    width: 70vw;
    font-size: 18pt;
}
.home_container_outer h3{
    font-size: 26pt;
    padding: 1rem;
    font-weight: 600;
}
.c_image{
    width: 60vw;
    height: 50vh;
}
.hc_button{
    font-size: 18pt;
    border-radius: 20px;
    white-space: nowrap;
    width: auto;
    padding: 1.25rem 1.5rem;
}
.home_sale_card{
    max-width: 500px;
    }
 }

 @media (min-width:2000px) { 
    .home_sale_card{
        max-width: 700px;
    }
    
    
     }





