.create_sale_item{
    height: auto;
    background-color: #242424;
    padding: 10rem 4rem;
    color: white;
    font-weight: 600;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.sale_item_form{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.sale_item_form button{
    height: auto;
    width: auto;
    padding: 0.5rem 1.25rem;
   background-color: #2a4c80;
    border: none;
    border-radius: 20px;
    font-family: 'Raleway';
    font-size: 18pt;
    font-weight: 600;
    color: white;
    align-self: center;
    margin-top: 1rem;
    margin-bottom: 3vh;
    cursor: pointer;
}


.sale_item_form button:hover{
    background-color: #ffffff;
    color: #2a4c80;
    transition: 1s;
}
