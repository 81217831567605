.eh{
    height: 65vh;
    color: white;
}
.eh h2{
    font-size: 20pt;
    font-weight: 700;
}
.related_container{
display: flex;
flex-direction: row;
white-space: nowrap;
width: 100%;
overflow-x: scroll !important;
}

.inner_container{
    display: flex;
    flex-direction: row;
    margin: 1rem;
}
.related_card {
    display: flex;
    flex-direction: column;
  height: 45vh;
  width: 50vw;
  background-image: linear-gradient(#4fa6736c, #dbb94b77 , #fb482c57) ;
  border-radius: 20px;
  padding: 1rem;
  margin: 1rem;
  box-shadow:3px 3px 10px 5px rgba(0, 0, 0, 0.25);
}

.rp{
    background-color: #242424;
    width: 60vw;
    border-radius: 20px;
    margin-left: -25px;
}

.h1r {
    margin: 0;
    white-space: normal;
    overflow-wrap: break-word;
    word-wrap: break-word;
    font-weight: 700;
    
  }

.related_card img{
    height: 9rem;
    width: 12rem;
    align-self: center;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: 11px 13px 10px 5px rgba(0, 0, 0, 0.25);
    
}
.related_card_p{
    margin: 0 !important;
}

.related_button{
    height: auto;
    width: auto;
    padding: .7rem .5rem !important;
    background-color: #4FA673;
        border: none;
        border-radius: 12px;
        font-family: 'Raleway';
        font-size: 9pt;
        font-weight: 600;
        color: white;
        margin-top: 1.5rem;
        margin-bottom: 3vh;
        cursor: pointer;
        
    }
    .related_button:hover{
        background-color: #ffffff;
        color: #4FA673;
        transition: 1s;
    }

    .NoRelated{
        font-size: 18pt;
        padding: 5rem;
        background-color: #242424;
        border-radius: 20px;
    }


@media (min-width:1025px) { 
    .eh{
        height: 750px;
    }
.related_card{
    height: 35vh;
    width: 25vw;
}
.h1r {
    font-size: 22pt;
  }
  .rp h2{
    font-size: 30pt;
  }
  .related_button{
    font-size: 18pt;
    width: 170px;
    align-self: center;

  }
  .related_card img{
    height: 15rem;
    width: 15rem;
}
    }


    
@media (min-width:1800px) { 
.eh{
    height: auto;
}
.rp{
    padding: 1rem;
}
.related_card{
    height: 45vh;
    width: 20vw;
}
.related_card img{
    height: 23rem;
    width: 23rem;
    margin: 1rem !important;
    box-sizing: border-box;
}


}


@media screen 
  and (min-width: 900px) 
  and (max-height: 900px)  { 
    .related_card{
        height: 500px;
        width: 400px;
    }
    .related_card img{
        height: 17rem;
        width: 17rem;
        margin: 1rem !important;
        box-sizing: border-box;
    }


}