.search_img{
    height: 42px;
    background-color: white;
}
.search_input{
    height: 40px;
    border: none;
}
.search_container{
    display: flex;
    flex-direction: row;
    justify-content: center;
}