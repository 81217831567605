.youtube_frame{
    height: 500px;
    width: 800px;
    margin: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
}
.youtube_frame2{
    height: 400px;
    width: 600px;
    margin: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
}
.video_header{
    font-size: 50pt;
    font-weight: 600;
    color:white;
    margin: 1.5rem;

}
.video_header2{
    font-size: 40pt;
    font-weight: 600;
    padding-top: 1rem;
    color:white;
    margin: 1.5rem;
}
.other_recents{
    margin: 5rem;
    border-radius: 20px;
}
.recent-videos{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.video_p_outer{
    display: flex;
    justify-content: center;
}
.video_p{
    color: white;
    font-size: 18pt;
    font-weight: 500;
    margin: 0rem 5rem;
    margin-bottom: 1rem;
    padding: 1rem;
    border-radius: 20px;
    width: 75vw;
    line-height: 1.8;
}
.latest_video_frame{
    margin: 5rem;
    border-radius: 20px; 

}
.latest_video_frame_outer{
    display: flex;
    justify-content: center;
    background-color: #24242452;
    border-radius: 20px;
    margin: 3rem;
}


@media (max-width:1050px) { 
    .youtube_frame{
    height: 200px;
      width: 300px;
    margin: 2rem;
    }
    .youtube_frame2{
        height: 200px;
        width: 300px;
        margin: 2rem;
    }
    .other_recents{
        margin: 1rem;
    }
    .video_header{
        font-size: 30pt
    } 
    .latest_video_frame{
        margin: 1rem; 
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .latest_video_frame_outer{
        display: block;
        margin: 1rem;
    }
    .video_p{
        font-size: 12pt;
        width: 80vw;
        line-height: 1.8;
    }
    .video_header2{
        font-size: 20pt;
        font-weight: 600;
        padding-top: 1rem;
    }
}