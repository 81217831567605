.popup_outer_shell {
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.popup_outer_shell p{
    font-size: 12pt;
    line-height: 2;
}
.popup_date{
    color: rgb(207, 201, 151);
    font-size: 12pt;
}

.popup_dark_background {
    position: absolute;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the alpha (fourth value) to control darkness */
    display: flex;
    justify-content: center;
}

.popup_inner {
    position: relative;
    z-index: 20 !important;
    height: 300px;
    width: 300px;
    margin-top: 20vh;
    padding: 2rem;
    border-radius: 20px;
    background-color: #242424;
    color: white;
    line-height: 1.5;
}
.popup_inner button{
    margin: 2rem;
    padding: 0.5rem 1rem;
    border: none;
    font-weight: 600;


}

.material-symbols-outlined{
    font-size: 40pt;
}

.hey{
    font-size: 14pt;
    font-weight: 600;
}

@media (min-width:1024px) { 

.popup_inner{
    height: 300px;
    width: 600px;
}

.popup_outer_shell p{
    font-size: 20pt;
}

}