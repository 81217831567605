.b3_img{
    background-image: url('https://images.unsplash.com/photo-1621792247511-7d86349379e7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1742&q=80');
    height: auto;
    width: 100vw;
   background-size: cover;
   display: flex;
   justify-content: center;
   padding-bottom: 2rem;
}
.b3_h4{
    background: -webkit-linear-gradient(left, #4FA673, #ffffff);
    background: -o-linear-gradient(right, #4FA673, #ffffff);
    background: -moz-linear-gradient(right,  #4FA673, #ffffff);
    background: linear-gradient(to right,  #4FA673, #ffffff); 
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   margin-left: 16vw;
   font-size: 12pt;
   font-weight: 600;
   padding-top: 2vh;
}
.b33{
    color: #DBBA4B;
    font-weight: 600;
    padding-bottom: 1.5rem;
}
.b_1{
    height: auto;
    width: 85vw;
    padding: 1rem;
    margin-top: 2.5vh;
    background-color: #242424;
    z-index: 2;
    border-radius: 12px;
    box-shadow: 11px 13px 10px 5px rgba(0, 0, 0, 0.25);
}
.banner button{
    height: auto;
    width: auto;
    padding: 1rem 1.25rem;
    background-color: #DBBA4B;
    border: none;
    border-radius: 12px;
    font-family: 'Raleway';
    font-size: 12pt;
    font-weight: 600;
    color: white;
    align-self: center;
    margin-top: 5vh;
    cursor: pointer;
    transition: 1s;
}
.banner button:hover{
    background-color: #ffffff;
    color: #DBBA4B;
}
.b_2 h1{
color: white;
font-size: 32pt;
font-weight: 700;
}


@media (min-width:1025px) { 
    .b_1{
        width: 60vw;
    }
    .b3_h4{
        font-size: 18pt;
    }
    .b33{
        font-size: 18pt;
        padding-bottom: 3rem;
    }
}
    @media (min-width:2000px) { 

            .b3_h4{
                font-size: 22pt;
            }
            .b33{
                font-size: 32pt;

            }
   
      }
    
    
    