.navbar__mobile{
    position: fixed;
    z-index: 10;
    color: white;   
    }
    .open_gray{
      background-color: #242424;
      padding-left: 1rem;
      margin-bottom: 1rem;
    }
    .mobile_nav_icon{
        width: 5rem;
        padding: 1rem 0;
    }
    .mobile_nav_container{
        display: flex;
        justify-content: space-between;
        height: 100vh;
        animation: fadeInLeft 0.5s;
        margin-top: -4vh;
    }
    .mobile_nav_container p{
     font-size: 10pt;   
    }
    .right_side_mobile_nav{
        width: 40vw;
        margin: 0.5rem;
        height: fit-content;
        margin-top: 5vh;

    }
    .mobile_logo{
        height: 1.5rem;
        width: auto;
    }
    .mobile_logo_container{
        height: 2rem;

    }
    
    .left_side_mobile_nav{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 94vh;
    }
    .left_side_mobile_nav li, a{
    text-decoration: none;
    color: white;
    }
    .navbar__dropdownItem_mobile{
    padding: 3rem 0.25rem;
 
    }
    .right_side_mobile_nav ul{
        animation: fadeInUp 0.5s;
        margin-top: 2.5rem;
        justify-self: center; 
        list-style-type: none;
        padding: 0; 
        margin: 0; 

    }
    .green_mn{
        border: #4FA673 2px solid;
        border-radius: 12px;
    }
    .yellow_mn{
        border: #DBBA4B 2px solid;
        border-radius: 12px;
    }
    .brown_mn{
        border: #95562E 2px solid;
        border-radius: 12px;
    }
    .navbar-toggle{
      background-color: #242424;
      border: none;
      width: 55px;
      height: 45px;
      padding-left: 7.5px;
    }


    

    
    
    @keyframes fadeInLeft {
        0% {
          opacity: 0;
          transform: translateX(-100%);
        }
        100% {
          opacity: 1;
          transform: translateX(0);
        }
      }
      
    @keyframes fadeInUp {
        0% {
          opacity: 0;
          transform: translateY(100%);
        }
        100% {
          opacity: 1;
          transform: translateX(0);
        }
      }
      
    
      @media (max-height:750px) {
        .mobile_nav_icon{
            width: 5rem;
            padding: 1rem 0;
            justify-self: center;
        }


      } 