.Seminars{
    width: 100vw;
    background-image: linear-gradient(#4FA673, #DBBA4B , #FB492C87 ) ;
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.seminars_welcome{
    height: auto;
    width: 60vw;
    margin: 5vh;
    background-color: #242424;
    border-radius: 12px;
    box-shadow: 11px 13px 10px 5px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-self: center;
    color: #4FA673;
    font-size: 12pt;
    padding: 5vh;
    font-weight: 600;
}
.seminars_welcome h1{
    color: #DBBA4B;
    font-size: 22pt;
    font-weight: 700;
}
.seminars_welcome p{
    padding: 1rem 0rem;
}

.seminars_body_main{
    background-color: #24242444;
    height: auto;
    width: 90vw;
    border-radius: 20px;
    box-shadow: 11px 13px 10px 5px rgba(0, 0, 0, 0.25);
   align-self: center;
   color: white;
   margin-bottom: 4rem;
}
.seminars_body_top h1{
    color: #DBBA4B;
    font-size: 30pt;
    font-weight: 700;
    padding: 3rem;
}
.seminars_body_top p{
    padding: 1rem 2rem;
    font-size: 12pt;
    line-height: 1.8;
}

.seminars_body_mid{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.seminars_body_mid h3{
    font-size: 20pt;
    padding: 2rem;
}
.seminars_list{
width: auto;
display: flex;
justify-content: center;
flex-direction: column;

}
.seminars_list ul{
    width: auto;
    background-color: #242424;
    border-radius: 20px;
    padding: 2rem;
   padding-bottom: 5rem;
   align-self: center;
   margin: 5rem 2rem;

}
.seminars_list li{
    font-size: 14pt;
    font-weight: 700;
    padding-top: 2.5rem;
    padding-bottom: 0.25rem;

}
.seminars_list span{
    font-size: 12pt;
    font-weight: 700;
    color: #95562E;
}
.seminars_icon{
width: 100%;   
}
.seminar_pic{
    height: 30vh;
    width: 80vw;
    object-fit: cover;
    border-radius: 20px;
    align-self: center;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    
}
.seminar_pic_section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 4rem;
}

.seminars_body_bottom{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 8rem;
}    
.seminars_body_bottom h1{
    font-size: 20pt;
    font-weight: 700;
    padding: 3rem;
    padding-bottom: 0rem;
}    
.seminar_form{
    align-self: center;
    padding: 4rem;
    color: black;
    width: auto;
}    
.s_decoy{
height: 15rem;
width: 23rem;
}
.s_visible{
height: 15rem;
width: 23rem;
}
.seminar_button{
    height: auto;
    width: auto;
    padding: 0.5rem 1rem;
    background-color: #DBBA4B;
    border: none;
    border-radius: 12px;
    font-family: 'Raleway';
    font-size: 12pt;
    font-weight: 600;
    color: white;
    align-self: center;
    cursor: pointer;
    margin: 0.5rem;
    box-shadow: 1px 3px 10px 5px rgba(0, 0, 0, 0.25);
}
.seminar_button:hover{
    background-color: #ffffff;
    color: #DBBA4B;
    transition: 1s
}
.seminars_body_bottom h2{
    font-size: 30pt;
    font-weight: 700;
    color:#DBBA4B;
}

.seminar_inner_container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-bottom: 1px white solid;
}



@media (min-width:1025px) { 
    .seminars_welcome{
        font-size: 18pt;
    }
    .seminars_welcome h1{
        color: #DBBA4B;
        font-size: 60pt;
        font-weight: 700;
    }
    .seminars_welcome p{
        padding: 0.5rem;
    }
    .seminars_body_main{
        width: 80vw;
    }
    .seminars_body_main p{
        font-size: 18pt;
        margin: 2rem 10rem;
    }
    .seminars_body_main h1{
        font-size: 50pt;
    }
    .seminars_body_mid{
        display: flex;
        justify-content: center;
    }
.seminars_icon{
    margin-top: 4rem;
    width: 50%;
}
.seminars_list{
    padding: 0;
}
.seminars_list li{ 
    font-size: 18pt;
}
.seminars_list span{ 
    font-size: 12pt;
}
.seminar_pic{
    height: 50vh;
    width: 55vw;
    margin: 2rem;
}
.s_decoy{
    height: 15rem;
    width: 35rem;
    }
    .s_visible{
    height: 15rem;
    width: 35rem;
    }

    .center_seminar_icon{
        display: flex;
        justify-content: center;
        margin-bottom: 10rem;
        margin-top: -10rem;
    }



    @media screen 
    and (min-width: 900px) 
    and (max-height: 900px)  { 
.s_visible{
    margin-top: -14rem;
}

  }


  @keyframes fadeIn{
    from {
      opacity: 0;
    }
    to {
      opacity: 1; 
    }
  }
}


@media (min-width: 1500px) {
    
    .seminars_body_mid{
        display: flex;
        justify-content: center;
        flex-direction: row;
    }


}


@media (min-width: 1700px) {
    .seminar_pic{
    width: 45vw;
    }

}