
.bios {
    width: 300px;
    margin: 2rem auto;

    padding: 15px;
    border-radius: 5px;
  }
  
  .bio_single {
    margin-bottom: 15px;
  }
  
  .bio_header {
    display: flex;
    align-items: center;
    background-color: #c9c9c9;
    padding: 1.5rem;
    color: black;
  }
  
  .bio_header h2 {
    margin: 0;
    margin-left: 10px;
    font-size: 1.5em;
  }
  
  .bio_header button {
    background-color: #4FA673;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 18px;
    border-radius: 5px;
  }
  
  .bio_header button:hover {
    background-color: #3b7754;
  }
  
  .bio_single p {
    margin: 1rem;
    padding: 1.5rem;
    border-radius: 15px;
    background-color: #24242436;
  }
  
  .bio_single.expanded p {
    max-height: 1000px; /* Set this high enough to fit your longest content */
    padding: 15px 0; /* Add padding for expanded state */
  }
  

  
@media (min-width:1025px) { 

  .bios {
    width: 65vw;
    font-size: 16pt;
    margin-top: 4rem;
  }
  .bio_single p {
    margin: 3rem;
    font-size: 15pt;
  }
  .bio_header{
    margin: 1.5rem;

    padding: 2rem;
  }


}
@media (min-width:1505px) { 

  .bios {
    width: 50vw;
  }
}