.newBlog{
    height: auto;
    background-color: #242424;
    padding-top: 15vh;
    color: white;
    font-weight: 500;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.newBlog_header{
    display: flex;
    flex-direction: column;
    border: 3px solid white;
    padding: 4rem;
}
.newBlog_header h1{
font-size: 28pt;
padding: 2rem;
}
.newBlog form{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.newBlog textarea{
    height: 15vh;
    width: 45vw;
}

.side_by_side{
    display: flex;
}
.newBlog_model{
    width: 35vw;
    display: flex;
    flex-direction: column;
}
.newBlog_model img{
    height: 28rem;
    width: auto;
    align-self: center;
}
.newBlog_model h1{
    font-size: 20pt;
    padding: 2rem;
}

.newBlog_form input{
    margin: 1rem;
    height: 3rem;
    width: 20rem;
  
}

.newBlog button{
    height: auto;
    width: auto;
    padding: 0.5rem 1.25rem;
   background-color: #2a4c80;
    border: none;
    border-radius: 20px;
    font-family: 'Raleway';
    font-size: 18pt;
    font-weight: 600;
    color: white;
    align-self: center;
    margin-top: 1rem;
    margin-bottom: 3vh;
    cursor: pointer;

}
.newBlog button:hover{
    background-color: #ffffff;
    color: #2a4c80;
    transition: 1s;
}
.b8{
    width: 400px !important;
    height: 200px !important;
}
.b9{
    width: 400px !important;  
    height: 200px !important;
}

.quill{
    height: 45vh;
}


@media (max-width: 750px){ 
.newBlog_model{
    display: none;
}
.side_by_side{
   display: block;
}


}