.banner_img_5{
    width: 30vw;
    align-self: center;
    margin: 1rem;
}

.instagram_lower{
    color: white;
    padding: 1.5rem;
}
.b_h52 {
    color: white;
    font-size: 20pt;
    font-weight: 600 !important;
    padding: 0 1rem;
    padding-bottom: 0.5vh;
    font-weight: 500;
}
.banner_button5{
    margin: 1rem;
}
.b_img5{
    background-image: url('https://images.unsplash.com/photo-1663173485691-e08cd39e6e66?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
    height: auto;
    width: 100vw;
   background-size: cover;
   display: flex;
   justify-content: center;
   padding-bottom: 2rem;
}

.corrales{
    width: 70vw;
    margin: 1rem;
    padding-bottom: 1.5rem;
}

@media (min-width:1025px) { 
    .banner_img_5{
        width: 15vw;
        padding-top: 2rem;
    }
    .corrales{
        width: 30vw;
        margin: 1rem;
    }

}


@media (min-width:1225px) { 
    .banner_img_5{
        width: 10vw;
    }
    .b_h52 {
        font-size: 28pt;
    }
    .banner_button5{
        margin: 2rem;
    }
    .corrales{
        width: 20vw;
    }

}


@media (min-width:1825px) { 
    .banner_img_5{
        width: 7vw;
    }
    .corrales{
        width: 15vw;
    }

}