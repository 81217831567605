.header{
    height: fit-content;
    z-index: 9;
}
.balloons{
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    height: 50vh;
    width: 100%;
    object-fit: cover;
  }
  .logo{
      height: auto;
      max-height: 28vh;
      max-width: 100%;
      width: auto;
      margin: 2rem auto;
      align-self: center;
  }

  .mobile_banner_logo{
    position: relative;
    margin-top: 3vh;
    max-width: 97%;
    width: auto;
    padding: 0.5rem;
    z-index: 2;
  }
  .mobile_banner{
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    height: 17vh;
    width: 100%;
    object-fit: cover;
    margin-bottom: 5rem;
  }
  .mobile_photo_banner{
    height: 20vh;
  }
  .active {
    transition: opacity 0.5s ease-in-out;
    opacity: 1;
  }
  ul{
    list-style: none;
  }




  
@media (min-width: 749px) { 

  .mobile_banner_logo{
    margin-top: 1vh;
  }
}